import getConfig from 'next/config'
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export const AUTOINSPECTOR_API_BASE_URL =
  serverRuntimeConfig?.AUTOINSPECTOR_API_BASE_URL ||
  publicRuntimeConfig?.AUTOINSPECTOR_API_BASE_URL

export const DASHBOARD_PATH = publicRuntimeConfig?.DASHBOARD_PATH

export const IS_FRONTEND_ENV = process.env.FRONTEND_ENV === 'testing'

export const STATUS_PAGE_URL = publicRuntimeConfig?.STATUS_PAGE_URL

export const GA_TRACKING_ID = publicRuntimeConfig?.GA_TRACKING_ID

export const AUTOINSPECTOR_HOST = publicRuntimeConfig?.AUTOINSPECTOR_HOST
