import {
  Box,
  Link as ChakraLink,
  Text,
  useBreakpointValue,
  chakra,
} from '@chakra-ui/react'
import AutoinspectorIcon from 'components/AutoinspectorIcon'
import { DASHBOARD_PATH, STATUS_PAGE_URL } from 'config/config.exporter'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { compose } from 'ssr/utils/compose'

const Home = (props) => {
  const { t } = useTranslation('common')
  const size = useBreakpointValue(
    {
      base: '20rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
    },
    {
      fallback: '50rem',
    },
  )
  return (
    <Box
      h='100vh'
      position={'relative'}
      bgImage="url('/assets/images/main-bg.jpg')"
      bgPosition='center'
      bgRepeat='no-repeat'
      bgSize={'cover'}
      filter='grayscale(0.3)'
    >
      <Box
        px={[8, 16, null, 24]}
        pt={8}
        h='full'
        zIndex={2}
        position={'relative'}
        color={'white'}
      >
        <Box display='flex' justifyContent='end' fontSize='sm'>
          <Box>
            <ChakraLink
              href={`${DASHBOARD_PATH}/login`}
              isExternal
              fontWeight={400}
            >
              {t('navigation:logIn')}
            </ChakraLink>
          </Box>
        </Box>
        <Box
          h='80%'
          display='flex'
          justifyContent={'center'}
          flexDir={'column'}
          pt={[0, 12, 24]}
        >
          <Box flexShrink={0} ml={[0, null, '-3']}>
            <AutoinspectorIcon variant='white' size={size} />
          </Box>
          <Text
            fontWeight={400}
            maxW={'container.lg'}
            fontSize={['16px', null, '20px']}
          >
            {t('firstTitleLineOne')}
            <br />
            {t('firstTitleLineTwo')}
          </Text>
        </Box>
        <Box
          display={'flex'}
          flexDir='row'
          justifyContent={'space-between'}
          alignItems={['start', 'end']}
          h={'10%'}
        >
          <Box
            display={'flex'}
            gap={6}
            alignItems={'end'}
            fontSize={'sm'}
            fontWeight={400}
          >
            <ChakraLink isExternal href={STATUS_PAGE_URL} fontSize='sm'>
              {t('navigation:apiState')}
            </ChakraLink>
            <ChakraLink as={Link} href='/legal'>
              {t('navigation:legal')}
            </ChakraLink>
            <ChakraLink as={Link} href='/contact'>
              {t('navigation:contact')}
            </ChakraLink>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={6}>
            <ChakraLink
              href={'https://www.linkedin.com/company/autoinspectorok/'}
              isExternal
              fontWeight={400}
            >
              <chakra.img src='assets/images/linkedin.png' w={6} h={6} />
            </ChakraLink>
            <ChakraLink
              href={'https://www.instagram.com/autoinspectorok/'}
              isExternal
              fontWeight={400}
            >
              <chakra.img src='assets/images/instagram.png' w={6} h={6} />
            </ChakraLink>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Home

export const getServerSideProps = compose(async (ctx, pageProps, fn) => {
  pageProps.props = {
    ...pageProps.props,
    ...(await serverSideTranslations(ctx.locale || 'es', [
      'common',
      'navigation',
    ])),
  }
  fn()
})
